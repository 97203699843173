import React from "react";
import Layout from "../components/Layout";
import { StaticQuery, graphql } from "gatsby";
import PageHeader from "../components/PageHeader";
import Section from "../components/Section";
import Seo from "../components/Seo";

const Values = ({ location }) => (
  <Layout>
    <Seo
      title="Our Values"
      description="Our Values"
      url={location.pathname}
      section="values"
    />
    <PageHeader title="Unsere Werte" />
    <Section>
      <div className="prose mx-auto">
        <StaticQuery
          query={graphql`
            {
              allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/values/" } }
                limit: 1
              ) {
                edges {
                  node {
                    html
                  }
                }
              }
            }
          `}
          render={(data) => (
            <div
              dangerouslySetInnerHTML={{
                __html: data.allMarkdownRemark.edges[0].node.html,
              }}
            />
          )}
        />
      </div>
    </Section>
  </Layout>
);

export default Values;
